import { Col, Row, Image } from "antd";
import mobilepic from "../../../assets/mobilepic.png";
import googleplay from "../../../assets/googleplay.png";
import appstore from "../../../assets/appstore.png";

const Downloadnow = () => {
  return (
    <div className="download-section">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={22}>
          <div className="culturally-persistent">
            <Row style={{justifyContent:"end", alignItems:"center"}}>
              <Col xs={24} md={10}>
                <div className="download-content">
                  <h2>Download <span>App</span> Now!</h2>
                  <small>
                    Select your device platform and get download start!
                  </small>
                  <div style={{paddingTop:"20px"}}>
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={googleplay}
                      className="app-pic"
                    />
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={appstore}
                      className="app-pic"
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={mobilepic}
                    className=""
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Downloadnow;
