import { useState } from "react";
import { Col, Row, Card, Image, Rate, Button, Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";

import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import cardimg4 from "../../../assets/card-img4.png";
import cardimg5 from "../../../assets/card-img5.png";
import cardimg6 from "../../../assets/card-img6.png";
import logo from "../../../assets/logo-header.png"
import { BASE_URL, CATE, CATEGORIES, UPLOADS_URL } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import swal from "sweetalert";
import { useSelector } from "react-redux";

import { useEffect } from "react";

const BlogcardPage = () => {
  const navigate = useNavigate();
    const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      image: cardimg1,
      title: "Lawn work/Land scape",
      description:
        "Transform your outdoor space with our expert lawn care and landscaping services. From mowing and trimming to planting and hardscaping, we'll make your yard a beautiful, inviting oasis.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 2,
      image: cardimg2,
      title: "Cleaning",
      description:
        "Our cleaning services ensure your space shines and sparkles. We offer deep cleaning, regular maintenance, and specialized solutions to keep your home or office fresh and tidy.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 3,
      image: cardimg3,
      title: "Moving/hauling",
      description:
        "Moving to a new place? Let us know and we our professional moving and hauling team will safely transport your belongings to your new home in a seamless and hassle-free.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 4,
      image: cardimg4,
      title: "Assembly",
      description:
        "Say goodbye to confusing instructions and frustrating DIY projects. Our assembly services take care of everything, from furniture and equipment assembly to ensuring it's all set up and ready to use.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 5,
      image: cardimg1,
      title: "Cosmetic/Beauty",
      description:
        "Do not have the energy to visit the salon? Contact our team of beauticians who will give you a complete makeover in the comfort of your home.    ",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 6,
      image: cardimg6,
      title: "Auto mechanic/towing",
      description:
        "When your vehicle needs attention, trust our auto mechanics to provide expert repairs and maintenance. We also offer towing services to ensure you and your vehicle stay safe on the road.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },

    {
      _id: 7,
      image: cardimg4,
      title: "Plumbing ",
      description:
        "Need a quick plumbing fix? Connect with our team of professional plumbers who will resolve all leakage and other plumbing to ensure your home’s water and drainage system run smoothly.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 8,
      image: cardimg5,
      title: "Ride share",
      description:
        "Ride sharing is an economical way of traveling. If you are looking for this affordable alternative of transportation, you can opt for I Got U’s ride sharing services. ",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 9,
      image: cardimg1,
      title: "Finance/taxes/banking",
      description:
        "Navigate the financial world with ease. Our experts offer a range of financial services, including tax preparation, banking advice, and financial planning to help you secure your financial future.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 10,
      image: cardimg5,
      title: "Handy man",
      description:
        "Tackle those unfinished household projects with the help of our skilled handymen. From minor repairs to home improvement tasks, we're your one-stop solution for keeping your space in top shape.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 11,
      image: cardimg6,
      title: "Snow removal",
      description:
        "When winter strikes, count on our snow removal services to keep your property safe and accessible. Our team is equipped to handle snow and ice removal efficiently.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },

    {
      _id: 12,
      image: cardimg1,
      title: "Tailoring/Dry cleaning",
      description:
        "For that perfect fit or clean, crisp clothing, turn to our tailoring and dry cleaning services. We'll keep your wardrobe looking sharp and tailored to your preferences.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 13,
      image: cardimg3,
      title: "Pet grooming",
      description:
        "Pamper your pets with our professional grooming services. From baths and haircuts to nail trims, we'll make sure your furry friends look and feel their best.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 14,
      image: cardimg2,
      title: "Child care/ day care/ babysitting",
      description:
        "Trust our reliable child care and babysitting services to provide a safe, nurturing environment for your children, ensuring their well-being while you're away. ",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 15,
      image: cardimg6,
      title: "Pest control",
      description:
        "Say goodbye to unwanted guests in your home. Our pest control services will effectively eliminate pests and keep your living space pest-free. ",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 16,
      image: cardimg4,
      title: "Travel",
      description:
        "Let us plan your dream vacation. Our travel experts curate unforgettable journeys, handling all the details, from flights and accommodations to activities and itineraries.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 17,
      image: cardimg2,
      title: "Roofing",
      description:
        "Protect your home with our roofing services. We offer expert roof repair and replacement to keep your property safe and secure in all weather conditions.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },

    {
      _id: 18,
      image: cardimg3,
      title: "Delivery",
      description:
        "Need something delivered fast and efficiently? Our delivery services ensure your packages and goods reach their destination on time, with care and reliability. ",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 19,
      image: cardimg5,
      title: "Electrician services",
      description:
        "Electrical problems are no match for our skilled electricians. From installations to repairs, we'll ensure your electrical systems are safe and fully operational.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 20,
      image: cardimg4,
      title: "Bartenders",
      description:
        "Make your events unforgettable with our professional bartending services. Our skilled bartenders will mix up the perfect cocktails and drinks to delight your guests.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 21,
      image: cardimg5,
      title: "Masonry",
      description:
        "Enhance your property with our masonry services. We specialize in brickwork, stonework, and concrete to create stunning hardscapes and structures.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
    {
      _id: 22,
      image: cardimg3,
      title: "Event planning",
      description:
        "Turn your vision into reality with our event planning services. From weddings to corporate gatherings, our event planners will handle every detail to create memorable occasions.",
      ratingstar: <Rate />,
      forlink: <Link to={"/blogDetails"}>Read more</Link>,
    },
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllCategories()
  }, []);


  console.log("tokejs",CATEGORIES.getAllCategories)


  const getAllCategories = () => {
    setLoading(true)
    Get(CATEGORIES.getAllCategories, token)
          .then((response) => {
            setLoading(false);
           setBlogs(response?.docs)
          })
          .catch((e) => {
            setLoading(false);
          });
      };



  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={19}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <h2 className="service-heading" style={{ padding: "24px 0" }}>
                CATEGORIES
              </h2>
            </Col>
          </Row>
          <div className="shop-page-main">
            {loading && (
              <Row>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <Row gutter={25}>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {!loading && (
              <Row>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <Row gutter={16}>
                    {blogs?.map((item, index) => (
                      <Col lg={8} key={index}>
                        <Card
                          // hoverable
                          className="service-card"
                          cover={
                            item.image == "default.jpg" ? <div style={{background:"#f6f6f6",height:"300px", borderRadius:"20px", display:'flex', justifyContent:'center', alignItems:'center'}}>
 <Image
                              preview={false}
                              style={{height:"150px", objectFit:'cover'}}
                              alt={"Failed to load image"}
                              src={logo}
                              className="circle-cards-img"
                            />
                            </div>:
                            <Image
                              preview={false}
                              style={{height:"300px", objectFit:'cover'}}
                              alt={"Failed to load image"}
                              src={UPLOADS_URL + "/uploads/" + item.image}
                              className="circle-cards-img"
                            />
                          }
                        >
                          <h4>{item.name}</h4>
                          {/* <span className="stars">{item.ratingstar}</span> */}
                          <p>{item.description}</p>
                          {/* <span className="card-view-detail"></span> */}
                          <Button
                            type="link"
                            danger
                            onClick={() => navigate("/categories/" + item._id)}
                          >
                            READ MORE
                          </Button>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BlogcardPage;



// 