import { useState } from "react";
import { Col, Row, Card, Image, Rate, Button, Skeleton, Layout } from "antd";

import { Link, useNavigate } from "react-router-dom";

import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import cardimg4 from "../../../assets/card-img4.png";
import cardimg5 from "../../../assets/card-img5.png";
import cardimg6 from "../../../assets/card-img6.png";
import { useEffect } from "react";

const ServiceblogDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      image: cardimg1,
      title: "Lawn Cleaning and Maintenance Services on I Got U ",
      description:
        "Trimming lawn grass and bushes is one of the most tedious chores that you have to do weekly. However, an easy way around it is to opt for a reliable lawn maintenance service that keeps your garden clean and beautiful...",
      ratingstar: <Rate />,
    },
    {
      _id: 2,
      image: cardimg2,
      title: "Cleaning",
      description:
        "Our cleaning services ensure your space shines and sparkles. We offer deep cleaning, regular maintenance, and specialized solutions to keep your home or office fresh and tidy.",
      ratingstar: <Rate />,
    },
    {
      _id: 3,
      image: cardimg3,
      title: "Moving/hauling",
      description:
        "Moving to a new place? Let us know and we our professional moving and hauling team will safely transport your belongings to your new home in a seamless and hassle-free.",
      ratingstar: <Rate />,
    },
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="shop-page">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={19}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <h2 className="service-heading" style={{ padding: "24px 0" }}>
                  BLOG Details
                </h2>
                <h4 className="heading-40">
                  Lawn Cleaning and Maintenance Services on I Got U{" "}
                </h4>
                <p className="descrip-p">
                  Trimming lawn grass and bushes is one of the most tedious
                  chores that you have to do weekly. However, an easy way around
                  it is to opt for a reliable lawn maintenance service that
                  keeps your garden clean and beautiful. I Got U brings five
                  different options for lawn maintenance. You can easily choose
                  one according to your requirements and keep it refreshing.
                  Here are the lawn maintenance services I Got U offers.
                </p>

                <h5 className="heading-20">1.	Lawn Mowing </h5>
                <p  className="descrip-p">If your lawn bushes and grass have grown wild because of lack of attention, you need lawn mowing. Our gardeners offer professional mowing services using advanced equipment. We ensure to keep the healthy grass unharmed and remove all the unwanted wild bushes.  </p>

                <h5 className="heading-20">2. Landscaping </h5>
                <p  className="descrip-p">Landscaping is an art that not everyone can master. Leave it for our professional gardeners, who know how to beautify a lawn by modifying its surface. You can enjoy satisfying landscaping work by opting for our landscaping services for your lawn.   </p>

                <h5 className="heading-20">3. Turfing </h5>
                <p  className="descrip-p">If the grass in your lawn looks dull, turfing is the solution for you. It is a method through which you can make your lawn look lush green. You can do it by adding a layer of grown patches of grass on the ground. However, preparing the ground for it is a daunting task, but we got you covered. Our team is skilled in giving a makeover to your lawn with our turfing services.   </p>

                <h5 className="heading-20">5. Fencing </h5>
                <p  className="descrip-p">We also offer fencing services for your garden. Whether you want to upgrade the old fence or need to install new ones, I Got U can help you. We offer unique and modern styled fencing options which can enhance the overall look of the garden.   </p>

                <h5 className="heading-20">Get a Complete Makeover of Your Lawns </h5>
                <p  className="descrip-p">We are a team of skilled gardeners who offer a variety of garden maintenance options. Our services include lawn mowing, landscaping, turfing, and fencing that will cover all your lawn cleaning and maintenance needs. So, explore our services to give your lawn a refreshing look.  </p>

              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ServiceblogDetails;
