import { useState,useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Radio,
  Button,
  Space,
  Collapse,
  Card,
  List,
  Image,
  Drawer,
  Rate,
  Select,
  Typography,
} from "antd";
import { TiLocation } from "react-icons/ti";
import { useSelector } from "react-redux";
import { MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import { BOOKING } from "../../../config/constants/api";
import { Get } from "../../../config/api/get";
import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import cardimg4 from "../../../assets/card-img4.png";
import cardimg5 from "../../../assets/card-img5.png";
import cardimg6 from "../../../assets/card-img6.png";
import swal from "sweetalert";



const ServicePage = ({state}) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [categories, setCategories] = useState([
    "Electronics",
    "Chef/Caterers",
    "Plumbers",
    "House Cleaning",
    "Car Services",
    "Painter",
  ]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { Meta } = Card;
  const [value, setValue] = useState(1);
  const [service, setServices] = useState([]);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);



  console.log("state",state)
  useEffect(() => {
    getAllServiceProviders();
  }, []);


  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const cardsdata = [
  ];


    // Rest of your component logic
    const getAllServiceProviders = () => {
      setLoading(true)
      Get(BOOKING.getAllServiceProviders, token,{lat:state.location.lat.toString(),log:state.location.lng.toString(),sevice:state.service,radius:"1000"})
            .then((response) => {
              setLoading(false);
              console.log("response",response)
              if (response?.status == 200) {
                swal("Success!", "Password Updated Successfully", "success");
              } else {
                swal("Oops!", response.response.data.message || response?.data?.message , "error");
              }
            })
            .catch((e) => {
              setLoading(false);
            });
        };


  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <h2 className="service-heading">
                Service Providers for your Private Event
              </h2>
              <h6 className="service-small-heading">
                Browse the best Service Providers that best meet your
                preferences
              </h6>
            </Col>
          </Row>
          <br/>
          {/* <Row style={{ width: "100%" }}>
            <Col lg={24} style={{textAlign:"end"}}>
              <Space wrap style={{padding:"30px 0"}}>
                <Select
                  defaultValue="Highest Rated"
                  // style={{
                  //   width: 120,
                  // }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "jack",
                      label: "Jack",
                    },
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                    {
                      value: "Yiminghe",
                      label: "yiminghe",
                    },
                    {
                      value: "disabled",
                      label: "Disabled",
                      disabled: true,
                    },
                  ]}
                />
                <Select
                  defaultValue="10"
                  // style={{
                  //   width: 120,
                  // }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "10",
                      label: "10",
                    },
                    {
                      value: "20",
                      label: "20",
                    },
                    {
                      value: "50",
                      label: "50",
                    },
                  ]}
                />
              </Space>
            </Col>
          </Row> */}
          <div className="shop-page-main">
            <Row>
              <Col xs={0} md={10} lg={7} xl={5}>
                <div className="left-filter-box">
                  <div className="filter-headong">
                    <p>Filters</p>
                  </div>
                  <div className="inner-filter-box">
                    <Form
                      name="basic"
                      initialValues={{
                        remember: true,
                      }}
                    >
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your Location......."
                          className="inp"
                        />
                        <TiLocation
                          key="location-icon"
                          className="location-icon"
                        />
                      </Form.Item>
                    </Form>
                    <hr />
                    <h6 className="filter-headings">Sort by</h6>
                    <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                        <Radio value={1}>Default Recommended</Radio>
                        <Radio value={2}>Popularity</Radio>
                      </Space>
                    </Radio.Group>
                    <hr />
                    <h6
                      className="filter-headings"
                      style={{ textAlign: "center" }}
                    >
                      Select Desired Service Type
                    </h6>
                    <>
                      <Collapse
                        defaultActiveKey={["1"]}
                        expandIconPosition="end"
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined
                            style={{ color: "#6A6A6A", fontSize: "18px" }}
                            rotate={isActive ? 90 : 180}
                          />
                        )}
                        items={[
                          {
                            key: "1",
                            label: (
                              <span style={{ fontWeight: "bold" }}>
                                {selectedCategory}
                              </span>
                            ),
                            children: (
                              <List
                                size="small"
                                dataSource={categories}
                                renderItem={(item) => (
                                  <List.Item
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSelectedCategory(item)}
                                  >
                                    <Typography.Text mark></Typography.Text>{" "}
                                    {item}
                                  </List.Item>
                                )}
                              />
                            ),
                          },
                        ]}
                      />
                    </>
                    <div className="apply-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className="web-btn"
                      >
                        Apply
                      </Button>
                    </div>

                    <div className="clear-btn">
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className=""
                      >
                        Clear
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={0} lg={0} xl={0}>
                <>
                  <MdMenu
                    style={{ fontSize: 26, color: "#000" }}
                    onClick={showDrawer}
                  />
                  <Drawer
                    // title="Basic Drawer"
                    placement="right"
                    onClose={onClose}
                    open={open}
                  >
                    <div className="left-filter-box">
                      <div className="filter-headong">
                        <p>Filters</p>
                      </div>
                      <div className="inner-filter-box">
                        <Form
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                        >
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your username!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Your Location......."
                              className="inp"
                            />
                            <TiLocation
                              key="location-icon"
                              className="location-icon"
                            />
                          </Form.Item>
                        </Form>
                        <hr />
                        <h6 className="filter-headings">Sort by</h6>
                        <Radio.Group onChange={onChange} value={value}>
                          <Space direction="vertical">
                            <Radio value={1}>Default Recommended</Radio>
                            <Radio value={2}>Popularity</Radio>
                          </Space>
                        </Radio.Group>
                        <hr />
                        <h6
                          className="filter-headings"
                          style={{ textAlign: "center" }}
                        >
                          Select Desired Service Type
                        </h6>
                        <>
                          <Collapse
                            defaultActiveKey={["1"]}
                            expandIconPosition="end"
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined
                                style={{ color: "#6A6A6A", fontSize: "18px" }}
                                rotate={isActive ? 90 : 180}
                              />
                            )}
                            items={[
                              {
                                key: "1",
                                label: (
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedCategory}
                                  </span>
                                ),
                                children: (
                                  <List
                                    size="small"
                                    dataSource={categories}
                                    renderItem={(item) => (
                                      <List.Item
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          setSelectedCategory(item)
                                        }
                                      >
                                        <Typography.Text mark></Typography.Text>{" "}
                                        {item}
                                      </List.Item>
                                    )}
                                  />
                                ),
                              },
                            ]}
                          />
                        </>
                        <div className="apply-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className="web-btn"
                          >
                            Apply
                          </Button>
                        </div>

                        <div className="clear-btn">
                          <Button
                            style={{
                              cursor: "pointer",
                            }}
                            className=""
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </>
              </Col>
              <Col xs={24} md={14} lg={17} xl={19}>
                <Row gutter={16}>
                  {cardsdata.map((item, index) => (
                    <Col lg={8} key={index}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={item.imageSrc}
                            className="circle-cards-img"
                          />
                        }
                      >
                        <h4>{item.title}</h4>
                        <span className="stars">{item.ratingstar}</span>
                        <p>{item.description}</p>
                        <span className="card-view-detail">{item.forlink}</span>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicePage;
