import { useState } from "react";
import { Col, Row, Button, Card, Rate, Image, Input, TextArea } from "antd";
import { AiOutlineShareAlt } from "react-icons/ai";
import elip26 from "../../../assets/elip26.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import providerslider1 from "../../../assets/providerslider1.png";
import providerslider2 from "../../../assets/providerslider2.png";
import { useNavigate } from "react-router-dom";

const ServiceProvidersProfile = () => {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [categories, setCategories] = useState([
    "Electronics",
    "Chef/Caterers",
    "Plumbers",
    "House Cleaning",
    "Car Services",
    "Painter",
  ]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const { Meta } = Card;
  const [value, setValue] = useState(1);

  const [chiefData, setChiefData] = useState({
    imageSrc: elip26,
    title: "About chief",
    chiefname: "John Doe",
    pricehour: "( Price Per Person : $100 Per hour )",
    description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum",
    servicetypes: "Italian, Mexican, Indian, Chinese, French, Thai, Turkish",
    city: "NYC",
    state: "New York State",
    country: "United States",
    postname: "Alexa Johnson",
    postdate: "Posted date: Jan 4, 2022",
    posttime: "Time: 08:00 AM",
    postdescp:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ",
    ratingstar: <Rate />,
    // forlink: <Link to={"/serviceProvidersbooking"}>View Details</Link>,
  });

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [provider, setProvider] = useState([
    providerslider1,
    providerslider2,
    providerslider1,
    providerslider2,
  ]);
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2 },
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%" }}>
            <Col lg={11}>
              <h4 className="chief-title">{chiefData.title}</h4>
              <h2 className="chief-name">{chiefData.chiefname}</h2>
              <h6 className="chief-pricehour">{chiefData.pricehour}</h6>
              <p className="chief-descrip">{chiefData.description}</p>
              <div className="book-and-share-btn">
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-btn"
                  onClick={() => navigate("/paymentDetails")}
                >
                  Book now
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-share-btn"
                >
                  <AiOutlineShareAlt />
                </Button>
              </div>
            </Col>
            <Col lg={13}>
              <AliceCarousel
                mouseTracking
                disableButtonsControls
                autoPlay
                infinite
                autoPlayInterval={1000}
                animationDuration={1000}
                responsive={responsive}
              >
                {provider.map((item, index) => {
                  return (
                    <div className="for-slider-x">
                      <Image
                        src={item}
                        preview={false}
                        className="slider-img"
                      />
                    </div>
                  );
                })}
              </AliceCarousel>
            </Col>
          </Row>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={24}>
                <h3 className="super-26">Service Type</h3>
                <h4 className="euclid-20">{chiefData.servicetypes}</h4>
                <div className="chief-location">
                  <span>City:{chiefData.city}</span>
                  <span>State:{chiefData.state}</span>
                  <span>Country:{chiefData.country}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={24}>
                <h3 className="chief-name">Reviews & ratings</h3>
                <span>{chiefData.ratingstar}</span>
              </Col>
            </Row>
          </div>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={14}>
                <div className="post-media">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={chiefData.imageSrc}
                    className="circle-cards-img"
                  />
                  <div>
                    <h3 className="super-26">{chiefData.postname}</h3>
                    <p className="chief-descrip" style={{ margin: "0" }}>
                      {chiefData.postdescp}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={10}>
                <div style={{ textAlign: "end" }}>
                  <p className="chief-descrip" style={{ margin: "0" }}>
                    {chiefData.postdate}
                  </p>
                  <p className="chief-descrip" style={{ margin: "0" }}>
                    {chiefData.posttime}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={24}>
                <>
                  <h3 className="super-26">Post a Comment</h3>
                  <TextArea
                    rows={8}
                    placeholder="Your message here.."
                    maxLength={6}
                    style={{ margin: "15px 0" }}
                  />
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    className="web-btn"
                  >
                    Post Now
                  </Button>
                </>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceProvidersProfile;
