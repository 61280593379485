import { Col, Row, Image, Button } from "antd";
import "react-alice-carousel/lib/alice-carousel.css";
import maskpic from "../../../assets/mask-pic.png";

const Serviceprovider = () => {
  return (
    <div className="service-provider-box">
      <Row style={{ width: "100%", justifyContent: "end" }}>
        <Col xs={23} md={24}>
          <div className="">
            <Row justify={"center"} style={{ paddingBottom: "60px" }}>
              <Col xs={24} md={19}>
                <Row style={{ alignItems: "center" }}>
                  <Col xs={24} md={10}>
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={maskpic}
                      className="forimg-border"
                    />
                  </Col>
                  <Col xs={24} md={14}>
                    <div className="provider-content-box">
                      <h6>About us</h6>
                      <h3>Here to Make Your Life Easier</h3>
                      <p>
                        I Got U is a team of home experts and carers who are
                        based in Milwaukee. We are experienced in serving many
                        homes in the city, covering a range of your needs. We
                        offer lawn mowing, cleaning, masonry, electrical work,
                        plumbing, snow removal, pest control, and a helping hand
                        for anything else you need at home. <br />
                        <br />
                        We are also good caregivers. So, if you are sick or need
                        to care for senior citizens, kids, or pets, we are here
                        to make your life easier. You can count on us, because
                        we got you!
                      </p>
                      <Button
                        style={{
                          cursor: "pointer",
                        }}
                        className="web-btn"
                      >
                        Find Now!
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Serviceprovider;
