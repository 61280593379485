import React, { useState,useEffect } from "react";
// import AuthLayout from "../../components/";
import {
  Layout,
  Input,
  Col,
  Row,
  Button,
  message,
  Steps,
  theme,
  Radio,
  DatePicker,
  Space,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";

import { AUTH,SERVICES } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import { TiLocation } from "react-icons/ti";
import LocationInput from "../../components/Location"
import swal from "sweetalert";



// import router from "next/router";

function Privateserviceprovider() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [location,setLocation] = useState(null)
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const [services, setServices] = React.useState([]);
  const [service, setService] = React.useState();
  const [date, setDate] = React.useState();



  useEffect(() => {
    getAllServices()
  }, []);


  const getAllServices = () => {
    setLoading(true)
    Get(SERVICES.getAllServices, token)
          .then((response) => {
            setLoading(false);
            setServices(response?.docs)
          })
          .catch((e) => {
            setLoading(false);
          });
      };



  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  

  const steps = [
    {
      title: "First",
      content: <>Find a <span style={{color:'red'}}>Private</span> Service Provider for you</>,
      content2:
        "A couple of details will assist us in locating the best Private Service Provider for you.",
    },
    {
      title: "Second",
      content: <>Please enter your <span style={{color:'red'}}>location</span></>,
      input: [
        <TiLocation key="location-icon" className="location-icon" />,
        <LocationInput setLocation={setLocation}/>,
      ],
    },
    {
      title: "third",
      content: <>Choose a <span style={{color:'red'}}>Service</span> Type</>,
      radio: (
        <div>
          <>
            <Radio.Group onChange={(e) => setService(e.target.value)} >
              {services && services?.length > 0 && services?.map(item => {
                return( <Radio.Button value={item.id}>{item.title}</Radio.Button>)
              }) }
             
              {/* <Radio.Button value="b">Electricians</Radio.Button>
              <Radio.Button value="c">Plumbers</Radio.Button>
              <Radio.Button value="d">House Cleaners</Radio.Button>
              <Radio.Button value="e">Car Services</Radio.Button>
              <Radio.Button value="f">Painters</Radio.Button> */}
            </Radio.Group>
          </>
        </div>
      ),
    },
    {
      title: "Last",
      content: "Choose the date",
      datepicker: (
        <div>
          <>
            <Space direction="vertical">
              <DatePicker onChange={(e)=> setDate(e)} />
            </Space>
          </>
        </div>
      ),
    },
  ];

  // AIzaSyBebqkpMyVBKJxP_b5bHIV7c7JFb23VI0Y

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    // lineHeight: '260px',
    textAlign: "center",
    // color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  // useEffect if user is already logged in
  // React.useEffect(() => {
  //   if (user && token) {
  //     navigate("/", { replace: true });
  //   }
  // }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="step-bg">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={16}>
            <>
              <Steps current={current} items={items} />
              <div className="step-content-box" style={contentStyle}>
                <div className="step-title">{steps[current].content}</div>
                <div className="step-dec">{steps[current].content2}</div>
                <div className="step-input"> {steps[current].input}</div>
                <div className="step-radio-btn"> {steps[current].radio}</div>
                <div className="step-datepicker"> {steps[current].datepicker}</div>
              </div>
              <div
                style={{
                  marginTop: 24,
                }}
              >
                {current > 0 && (
                  <Button
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prev()}
                    className="web-btn"
                    
                  >
                    Previous
                  </Button>
                )}

                {current < steps?.length - 1 && (
                  <Button
                    className="web-btn"
                    type="primary"
                    onClick={() => next()}
                    disabled={current == 1 && !location || current == 2 && !service}
                  >
                    Continue
                  </Button>
                )}
                {current === steps?.length - 1 && (
                  <Button
                    type="primary"
                    disabled={current == 3 && !date}
                    // onClick={() => message.success("Processing complete!")}
                    onClick={() => navigate("/serviceProvidersPrivateEvent",{state:{location,service,date}})}
                    className="web-btn"
                  >
                    Done
                  </Button>
                )}
                
              </div>
            </>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Privateserviceprovider;
