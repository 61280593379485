import React from "react";
// import AuthLayout from "../../components/";
import { Layout, Col, Row, Button, theme, Form, Input } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";

import swal from "sweetalert";

function ContactUs() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = () => {
    swal("", "Your message has been sent", "success");
  };

  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={15}>
            <div className="blur-bg-inner-card">
              <div className="blur-bg-inner-card-form">
                <h2 className="heading-h2">Contact us</h2>
                <h6>Reach out to us through the form below</h6>

                <Form
                  name="basic"
                  layout="vertical"
                  //   labelCol={{
                  //     span: 8,
                  //   }}
                  //   wrapperCol={{
                  //     span: 16,
                  //   }}
                  //   style={{
                  //     maxWidth: 600,
                  //   }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Your Full Name "
                    name="cardholdername"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your full name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your full name" />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="cardnumber"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your emai",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your emai" />
                  </Form.Item>

                  <Form.Item
                    label="Subject"
                    name="cvv"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your subject",
                      },
                    ]}
                  >
                    <Input placeholder="Enter subject" />
                  </Form.Item>

                  <Form.Item
                    label="Message"
                    name="cvv"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Message",
                      },
                    ]}
                  >
                    <TextArea
                      rows={8}
                      placeholder="Your message here.."
                      maxLength={6}
                      style={{ margin: "15px 0" }}
                    />
                  </Form.Item>

                  <Form.Item colon={false}>
                    <Button
                      htmlType="submit"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      className="web-btn"
                      onClick={handleChange}
                      // onClick={handleShow1}
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ContactUs;
