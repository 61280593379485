import { useState } from "react";
import { Col, Row, Image, Avatar } from "antd";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { AiFillStar } from "react-icons/ai";
import elip from "../../../assets/elip.png";

const Makeusproud = () => {
  const [services, setServices] = useState([
    {
      title: "Efficient Services",
      des: "I contacted them for their electrical services as we had a short circuit. They responded promptly and efficiently repaired it.",
      imageSrc: elip,
      altText: "Ben Stokes",
    },
    {
      title: "Superb Cleaners!",
      des: "I opted for I Got U cleaning services and was amazed by how dedicatedly they cleaned my kitchen and lawn.",
      imageSrc: elip,
      altText: "Carla Carte",
    },
    {
      title: "Excellent Services!",
      des: "I loved their cleaning services because they work professionally at such affordable prices. ",
      imageSrc: elip,
      altText: "James Anderson",
    },
    {
      title: "Superb Cleaners!",
      des: "I opted for I Got U cleaning services and was amazed by how dedicatedly they cleaned my kitchen and lawn.",
      imageSrc: elip,
      altText: "Carla Carte",
    },
    {
      title: "Efficient Services",
      des: "I contacted them for their electrical services as we had a short circuit. They responded promptly and efficiently repaired it.",
      imageSrc: elip,
      altText: "Ben Stokes",
    },
    {
      title: "Excellent Services!",
      des: "I loved their cleaning services because they work professionally at such affordable prices. ",
      imageSrc: elip,
      altText: "James Anderson",
    },
  ]);
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1000: { items: 2 },
    1024: { items: 3 },
  };
  

  return (
    <div className="make-proud">
      <Row style={{ width: "100%", justifyContent: "end" }}>
        <Col xs={23} md={24}>
          <div className="">
            <Row style={{ paddingBottom: "60px" }}>
              <Col xs={24} md={24}>
                <div className="heading-box">
                  <h6>Review & Feedback help you grow in several ways</h6>
                  <h4>What Make Us Proud</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24}>
                <div className="abc123">
                <AliceCarousel
                  mouseTracking
                  paddingLeft={200}
                  paddingRight={200}
                  autoPlayInterval={2000}
                  disableButtonsControls
                  //   autoPlay
                  infinite
                  responsive={responsive}
                  
                >
                  {services.map((item, index) => {
                    return (
                      <div className="proud-card">
                        <div>
                          <AiFillStar
                            style={{ fontSize: "20px", color: "#FFBB00" }}
                          />
                          <AiFillStar
                            style={{ fontSize: "20px", color: "#FFBB00" }}
                          />
                          <AiFillStar
                            style={{ fontSize: "20px", color: "#FFBB00" }}
                          />
                          <AiFillStar
                            style={{ fontSize: "20px", color: "#FFBB00" }}
                          />
                          <AiFillStar
                            style={{ fontSize: "20px", color: "#FFBB00" }}
                          />
                        </div>
                        <h6>{item.title}</h6>
                        <p>{item.des}</p>
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ flexShrink: "0" }}>
                              <Avatar
                                size={64}
                                icon={
                                  <Image
                                    preview={false}
                                    alt={"Failed to load image"}
                                    src={item.imageSrc}
                                    width={"100%"}
                                  />
                                }
                              />
                            </div>
                            <h5 className="media-name" style={{flexGrow:"1", marginLeft:"10px"}}>{item.altText}</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </AliceCarousel>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Makeusproud;
