import { Col, Row, Image } from "antd";
import Workicon1 from "../../../assets/work-icn.png";

const Howitwork = () => {
  return (
    <div className="how-work-section">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={20} lg={18}>
          <div className="culturally-persistent">
            <Row>
              <Col xs={24} md={24}>
                <div className="heading-box">
                  <h6>How IGOTU Services Works</h6>
                  <h4>How It Works</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={8} lg={8}>
                <div className="circle-cards circle-cards1-colour">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={Workicon1}
                    className="circle-cards-img"
                  />
                  <h5>Choose What To Do</h5>
                  <p>
                    Select date & time you'd like your Professional to show up!
                  </p>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="circle-cards circle-cards2-colour">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={Workicon1}
                    className="circle-cards-img"
                  />
                  <h5>Choose What To Do</h5>
                  <p>
                    Select date & time you'd like your Professional to show up!
                  </p>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="circle-cards circle-cards3-colour">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={Workicon1}
                    className="circle-cards-img"
                  />
                  <h5>Choose What To Do</h5>
                  <p>
                    Select date & time you'd like your Professional to show up!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Howitwork;
