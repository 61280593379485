import React, { useState } from "react";
// import AuthLayout from "../../components/";
import { Layout, Col, Row, Button, theme,  DatePicker, Form, Input, } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import { BsArrowLeft } from "react-icons/bs";

import swal from "sweetalert";



function PaymentDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleChange = () => {
    swal("","Your payment has been made successful!","success")
  };



  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();


  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={15}>
            <div className="blur-bg-inner-card">
              <h2 className="heading-h2">Enter Your Payment Details</h2>

              <div className="blur-bg-inner-card-form">
                <Form
                  name="basic"
                  layout="vertical"
                  //   labelCol={{
                  //     span: 8,
                  //   }}
                  //   wrapperCol={{
                  //     span: 16,
                  //   }}
                  //   style={{
                  //     maxWidth: 600,
                  //   }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Cardholder Name"
                    name="cardholdername"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your cardholder name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter cardholder name" />
                  </Form.Item>

                  <Form.Item
                    label="Credit/Debit Card Number "
                    name="cardnumber"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your card number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your card number" />
                  </Form.Item>

                  <Form.Item
                    label="Expiry Date"
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Form.Item
                      name="year"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <DatePicker onChange={onChange} picker="month" />
                    </Form.Item>
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}
                    >
                      <DatePicker onChange={onChange} picker="year" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label="CVV"
                    name="cvv"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your CVV number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter CVV number" />
                  </Form.Item>

                  <Form.Item colon={false}>
                    <Button
                      htmlType="submit"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      className="web-btn"
                      onClick={handleChange}
                      // onClick={handleShow1}
                    >
                      Proceed
                    </Button>
                  </Form.Item>

                  <Form.Item colon={false} style={{ textAlign: "center" }}>
                    <Button
                      htmlType="submit"
                      style={{
                        cursor: "pointer",
                      }}
                      className="back-btn"
                      onClick={() => navigate(-1)}
                    >
                      <BsArrowLeft /> Back
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default PaymentDetails;
