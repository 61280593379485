import {
  Col,
  Row,
  Avatar,
  Form,
  Button,
  Input,
  Upload,
  DatePicker,
  Space,
  Select
} from "antd";
import { useState } from "react";
import { UPLOADS_URL } from "../../config/constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TbCameraPlus } from "react-icons/tb";
import { USERS } from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { addUser } from "../../redux/slice/authSlice";

import swal from "sweetalert";
import { Post } from "../../config/api/post";

import profileimg from "../../assets/profileimg.png";

const ProfileTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const user = useSelector((state) => state.user.userData);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const [loading, setLoading] = useState(false);

  // const [user, setUser] = useState({
  //   id: 1,
  //   fname: "Easin",
  //   lname: "Arafat",
  //   mnumber: "+ 1 23456 789",
  //   email: "dummyemail@example",
  //   gender: "Male",
  //   date: "10-05-1990",
  // });



  console.log("user",user)

  const onFinish = (values) => {

    setLoading(true);
    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    Post(USERS.EditProfile, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        setLoading(false);
        if (response?.status == 200) {
          console.log(response?.data);
          dispatch(addUser({ user: response.data.user, token: token }));

          swal("Success!", "Profile Updated Successfully", "success");
          setLoading(false);
          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };





  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  console.log("LLLL",UPLOADS_URL+"/" + user?.image)

  return (
    <div className="profile-right-box">
      <h3 className="text-40">Profile Information</h3>
      <Row>
        {/* <Col lg={6}>1</Col> */}
        <Col lg={24} md={24}>
          <div className="boxDetails">
            <>
              <Row style={{ padding: "20px" }}>
                <Col xs={24} md={24}>
                  <Row style={{ justifyContent: "" }}>
                    <Col xs={24} md={24} lg={24}>
                      <Form
                        layout="horizontal"
                        name="basic"
                        labelCol={{
                          span: 0,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        initialValues={user}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <Row style={{ alignItems: "center" }}>
                          <Col lg={6} md={10} sm={24}>
                            {editMode ? (
                              <Form.Item name="image">
                                <Upload
                                  name="image"
                                  showUploadList={false}
                                  style={{ position: "relative" }}
                                  beforeUpload={(file) => {
                                    setImageNew(URL.createObjectURL(file));
                                    return false;
                                  }}
                                >
                                  {" "}
                                  <div className="camra-icn"
                                    style={{
                                      padding: "8px",
                                      position: "absolute",
                                      right: 30,
                                      zIndex: 2,
                                      bottom: 10,
                                      backgroundColor: "#F00",
                                      display: "flex",
                                      maxWidth: "fit-content",
                                      color: "white",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <TbCameraPlus />
                                  </div>{" "}
                                  <Avatar
                                    size={300}
                                    src={
                                      imageNew
                                        ? imageNew
                                        : !user?.image
                                        ? profileimg
                                        : UPLOADS_URL + "/" + user?.image
                                    }
                                    className="avtr"
                                  />
                                </Upload>
                              </Form.Item>
                            ) : (
                              <Avatar
                              size={300}
                                src={
                                  !user?.image
                                    ? profileimg
                                    : UPLOADS_URL + "/" + user?.image
                                }
                                className="avtr"
                              />
                            )}
                          </Col>
                          <Col lg={1} md={1} sm={0}></Col>
                          <Col lg={17} md={13} sm={24}>
                            <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="First Name"
                                  name="firstname"
                                  initialValue={user?.firstname}
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      required: true,
                                      message: "Please input name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Easin"
                                    style={{
                                      background: "white",
                                      fontSize: "14px",
                                      padding: "6px 20px",
                                    }}
                                  />
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24} sm={24}>
                                    <Row>
                                      <Col lg={6} md={11} sm={24}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          First Name{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13} sm={24}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.firstname}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="Last Name"
                                  name="lastname"
                                  initialValue={user?.lastname}
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      required: true,
                                      message: "Please input name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Arafat"
                                    style={{
                                      background: "white",
                                      fontSize: "14px",
                                      padding: "6px 20px",
                                    }}
                                  />
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24}>
                                    <Row>
                                      <Col lg={6} md={11}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          Last Name{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.lastname}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="Mobile Number"
                                  name="phone_no"
                                  initialValue={user?.phone_no}
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      required: true,
                                      message: "Please input name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="23456 789"
                                    style={{
                                      background: "white",
                                      fontSize: "14px",
                                      padding: "6px 20px",
                                    }}
                                  />
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24}>
                                    <Row>
                                      <Col lg={6} md={11}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          Mobile Number{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.phone_no}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="Email Address"
                                  name="email"
                                  initialValue={user?.email}
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      required: true,
                                      message: "Please input name!",
                                    },
                                  ]}
                                >
                                  <Input
                                  disabled
                                    size="large"
                                    placeholder="dummyemail@example"
                                    style={{
                                      background: "white",
                                      fontSize: "14px",
                                      padding: "6px 20px",
                                    }}
                                  />
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24}>
                                    <Row>
                                      <Col lg={6} md={11}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          Email Address{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.email}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                            {/* <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="Gender"
                                  name="gender"
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      required: true,
                                      message: "Please input name!",
                                    },
                                  ]}
                                >
                                  <Space wrap>
                                    <Select
                                      defaultValue="male"
                                      style={{
                                        width: 120,
                                      }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: "male",
                                          label: "Male",
                                        },
                                        {
                                          value: "female",
                                          label: "Female",
                                        },
                                      ]}
                                    />
                                  </Space>
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24}>
                                    <Row>
                                      <Col lg={6} md={11}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          Gender{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.gender}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row className="for-row-margin">
                              {editMode ? (
                                <Form.Item
                                  label="Date of Birth"
                                  name="birth"
                                  rules={[
                                    {
                                      type: "text",
                                      // warningOnly: true,
                                    },
                                    {
                                      // required: true,
                                      message: "Please input date!",
                                    },
                                  ]}
                                >
                                  <Space direction="vertical">
                                    <DatePicker onChange={onChange} />
                                  </Space>
                                </Form.Item>
                              ) : (
                                <>
                                  {" "}
                                  <Col lg={24} md={24}>
                                    <Row>
                                      <Col lg={6} md={11}>
                                        <h5
                                          style={{
                                            display: "inline",
                                          }}
                                          className="profile-lable"
                                        >
                                          Date of Birth{" "}
                                        </h5>
                                      </Col>
                                      <Col lg={12} md={13}>
                                        <h5
                                          style={{
                                            display: "block",
                                          }}
                                          className="profile-text"
                                        >
                                          {user?.date}
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </>
                              )}
                            </Row> */}
                            <br/>
                          </Col>
                        </Row>
                        <Row justify="center">
                          {editMode && (
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                size={"large"}
                                style={{ padding: "12px 40px", height: "auto" }}
                                className="web-btn"
                                onClick={() => setEditMode(true)}
                              >
                                Update Profile
                              </Button>
                            </Form.Item>
                          )}
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                  {!editMode && (
                    <Row>
                      <Col lg={7}></Col>
                      <Col lg={17}>
                        <Button
                          type="primary"
                          htmlType="button"
                          size={"large"}
                          className="web-btn"
                          onClick={() => setEditMode(true)}
                        >
                          Edit info
                        </Button>

                        <Button
                          type="primary"
                          htmlType="button"
                          size={"large"}
                          className="web-btn change-pas-btn"
                          onClick={() => navigate("/myProfile/updatePassword")}
                        >
                          Change Password
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileTab;
