import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Get } from "../../config/api/get";
import { USERS } from "../../config/constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

function UserDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([
    {
      id: 1,
      orderNumber: "2341",
      orderdate: "mm/dd/yy",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      bookingdate: "mm/dd/yy",
      jobtime: " HH:MM:sS",
      customerName: " Harry Lee",
      email: "test@email.com",
      serviceProviderName: "John Doe",
      paymentMethod: "Card",
      subTotal: "000.00",
      TotalAmount: "000.00",
      isActive: true,
    },
    {
      id: 2,
      orderNumber: "2341",
      orderdate: "2341",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      bookingdate: "mm/dd/yy",
      jobtime: " HH:MM:sS",
      customerName: " Harry Lee",
      email: "test@email.com",
      serviceProviderName: "John Doe",
      paymentMethod: "Card",
      subTotal: "000.00",
      TotalAmount: "000.00",
      isActive: false,
    },
    {
      id: 3,
      orderNumber: "2341",
      orderdate: "2341",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      bookingdate: "mm/dd/yy",
      jobtime: " HH:MM:sS",
      customerName: " Harry Lee",
      email: "test@email.com",
      serviceProviderName: "John Doe",
      paymentMethod: "Card",
      subTotal: "000.00",
      TotalAmount: "000.00",
      isActive: false,
    },
    // Add more user objects as needed
  ]);

  useEffect(() => {
    getUser();
  }, []);

  console.log("JJJJJ", window.location);

  const getUser = async () => {
    setLoading(true);
    // const user = await Get(`${USERS.getOne}${id}`, token);
    // setUser(user);

    let _user = users.find((item) => item.id == id);

    console.log("_user", _user);
    setUser(_user);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        USERS.toggleStatus + "/" + user._id,
        token,
        {}
      );
      const newUser = { ...user };

      newUser.isActive = !user.isActive;
      setModalOpen(false);
      setUser(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="profile-right-box">
        <h3 className="text-40">Order Details</h3>
      </div>

      <Row justify={"center"}>
        <Col md={22}>
          <h4 className="order-detail-heading">Order information</h4>
        </Col>
      </Row>

      <div className="profile-right-box">
        <Row style={{ padding: "20px" }}>
          <Col xs={24} md={16}>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Order Date : </h5>
                  <h5>{user?.orderdate}</h5>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Order ID : </h5>
                  <h5>{user?.orderNumber}</h5>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Booking Date : </h5>
                  <h5>{user?.bookingdate}</h5>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Job Time : </h5>
                  <h5>{user?.jobtime}</h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row justify={"center"}>
        <Col md={22}>
          <h4 className="order-detail-heading">Account Information</h4>
        </Col>
      </Row>

      <div className="profile-right-box">
        <Row style={{ padding: "20px" }}>
          <Col xs={24} md={16}>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Customer Name : </h5>
                  <h5>{user?.customerName}</h5>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Email : </h5>
                  <h5>{user?.email}</h5>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Service Type : </h5>
                  <h5>{user?.serviceType}</h5>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Service Provider Name : </h5>
                  <h5>{user?.serviceProviderName}</h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Row justify={"center"}>
        <Col md={22}>
          <h4 className="order-detail-heading">Payment Information</h4>
        </Col>
      </Row>

      <div className="profile-right-box">
        <Row style={{ padding: "20px" }}>
          <Col xs={24} md={24}>
            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <div className="for-detail-flex">
                  <h5>Payment Method : </h5>
                  <h5>{user?.paymentMethod}</h5>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "10px", textAlign: "end" }}>
              <Col xs={24} md={24} lg={24}>
                <div
                  className="for-detail-flex"
                  style={{ justifyContent: "end" }}
                >
                  <h5>Sub Total : </h5>
                  <h5>{user?.subTotal}</h5>
                </div>
                <div
                  className="for-detail-flex"
                  style={{ justifyContent: "end" }}
                >
                  <h5>Total Amount Paid : </h5>
                  <h5>{user?.TotalAmount}</h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default UserDetails;
