import React from "react";
import { Col, Row, Button, Form, Input, Checkbox } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { AUTH } from "../../config/constants/api";
import { Post } from "../../config/api/post";



const UpdatePassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const [loading, setLoading] = useState(false);



  // Rest of your component logic
  const onFinish = (values) => {
setLoading(true)
    Post(AUTH.changepassword, values, token)
      .then((response) => {
        setLoading(false);
        console.log("response",response)
        if (response?.status == 200) {
          swal("Success!", "Password Updated Successfully", "success");
          form.resetFields()
        } else {
          swal("Oops!", response.response.data.message || response?.data?.message , "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="profile-right-box">
      <h3 className="text-40">Update Password</h3>
      <Row>
        <Col lg={10} md={13}>
          <div className="boxDetails">
            <Form
            form={form}
              layout="vertical"
              name="basic"
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Current Password"
                name="currentpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your Current Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "password must be atleast 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your old password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input New Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "password must be atleast 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your new password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmpassword"
                rules={[
                  {
                    required: true,
                    message: "Please input Confirm Password!",
                  },
                  {
                    type: "string",
                    min: 8,
                    message: "password must be atleast 8 characters!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your Confirm your password"
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    padding: "10px 20px",
                  }}
                />
              </Form.Item>
              <br />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="web-btn"
                  onClick={() => setEditMode(true)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Update
                </Button>
                <Button
                  type="primary"
                  htmlType="button"
                  className="web-btn-trsp"
                  style={{
                    cursor: "pointer",
                    marginLeft: "16px",
                  }}
                  onClick={()=> navigate(-1)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
            <br />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpdatePassword;
