import React from "react";
import { Layout, Col, Row, Button, Form, Input } from "antd";
import { useNavigate } from "react-router";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("/forgot-password-2");
    // router.push("/forget-password-2")
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background loginas-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col lg={10}>
            <div className="login-card">
              <h4 className="auth-card-title">Reset Password</h4>
              <h4 className="auth-card-sub-text">Enter an email address to receive a verification code</h4>
              <Form
                layout="vertical"
                name="basic"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input valid email!",
                      // warningOnly: true,
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter your email address"
                    style={{
                      borderRadius: "5px",
                      background: "white",
                      fontSize: "14px",
                      padding: "10px 20px",
                    }}
                  />
                </Form.Item>
                <br />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="web-btn"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={() => navigate("/forget-password-2")}
                  >
                    proceed
                  </Button>
                </Form.Item>
                <div className="already-login">
                  Back to <span onClick={() => navigate("/login")}>Login</span>{" "}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ForgetPassword;
