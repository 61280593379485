import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import Homepage from "../../views/homepage";
import Privateserviceprovider from "../../views/privateserviceprovider";
import ServiceProvidersPrivateEvent from "../../views/serviceProvidersPrivateEvent";
import ServiceProvidersbooking from "../../views/serviceProvidersbooking";
import PaymentDetails from "../../views/paymentDetails";
import ContactUs from "../../views/contactUs";
import AboutUs from "../../views/aboutUs";
import CreateAccount from "../../views/createAccount";
import LoginAs from "../../views/loginAs";
import Login from "../../views/login";
import Login2 from "../../views/login2";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import Notifications from "../../views/notifications";
import Serviceblog from "../../views/serviceblog";
import ServiceblogDetails from "../../views/serviceblog/serviceblogCards/serviceblogDetails";
import ServiceblogDetails2 from "../../views/serviceblog/serviceblogCards/serviceblogDetails2";
import ServiceblogDetails3 from "../../views/serviceblog/serviceblogCards/serviceblogDetails3";
import Blog from "../../views/blog";
import BlogDetails from "../../views/blogDetails";
import MyProfile from "../../views/myProfile";
import ProfileTab from "../../views/myProfile/profileTab";
import OrderHistory from "../../views/myProfile/orderHistory";
import PushNotification from "../../views/myProfile/pushNotification";
import UpdatePassword from "../../views/myProfile/updatePassword";
import OrderHistoryDetail from "../../views/myProfile/orderHistoryDetail";

import UserAuthCheck from "../../components/AuthCheck/UserAuthCheck";
const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Homepage />
            </ClientLayout>
          }
        />
        <Route
          path="/privateserviceprovider"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Privateserviceprovider />
            </ClientLayout>
          }
        />
        <Route
          path="/serviceProvidersPrivateEvent"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ServiceProvidersPrivateEvent />
            </ClientLayout>
          }
        />

        <Route
          path="/serviceProvidersbooking"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ServiceProvidersbooking />
            </ClientLayout>
          }
        />
        <Route
          path="/paymentDetails"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PaymentDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/contactUs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ContactUs />
            </ClientLayout>
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AboutUs />
            </ClientLayout>
          }
        />
        <Route
          path="/createAccount"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateAccount />
            </ClientLayout>
          }
        />
        <Route
          path="/loginAs"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <LoginAs />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/loginServiceProvider"
          index
          element={
            <ClientLayout
              head={{ title: "Login", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-1"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword2 />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ForgetPassword3 />
            </ClientLayout>
          }
        />
        <Route
          path="/notifications"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Notifications />
            </ClientLayout>
          }
        />
        <Route
          path="/categories"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Blog />
            </ClientLayout>
          }
        />
        <Route
          path="/categories/:id"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <BlogDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/serviceblog"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Serviceblog />
            </ClientLayout>
          }
        />

        <Route
          path="/serviceblogDetails/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ServiceblogDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/serviceblogDetails2/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ServiceblogDetails2 />
            </ClientLayout>
          }
        />
        <Route
          path="/serviceblogDetails3/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ServiceblogDetails3 />
            </ClientLayout>
          }
        />
        <Route
          path="/myProfile"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MyProfile />
            </ClientLayout>
          }
        >
          <Route path="" index element={<ProfileTab />} />
          <Route path="order_history" element={<OrderHistory />} />
          <Route path="pushNotification" element={<PushNotification />} />
          <Route path="updatePassword" element={<UpdatePassword />} />
          <Route
            path="orderHistoryDetail/:id"
            index
            element={
              <UserAuthCheck>
                <OrderHistoryDetail />
              </UserAuthCheck>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
