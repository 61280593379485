import { useState } from "react";
import { Col, Row, Button, Card, Rate, Image, Input } from "antd";
import elip26 from "../../../assets/elip26.png";
import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from "react-router-dom";
import blogpic from "../../../assets/blogpic.png";
import featuredPostpic from "../../../assets/featured-post.png";

const InnerBlog = () => {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const [selectedCategory, setSelectedCategory] = useState("");
  const { Meta } = Card;
  const [value, setValue] = useState(1);

  const [chiefData, setChiefData] = useState({
    blogpic: blogpic,
    imageSrc: elip26,
    title: "About chief",
    chiefname: "The standard Lorem Ipsum passage,used since the 1500s",
    pricehour: "( Price Per Person : $100 Per hour )",
    description:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of  (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum",
    servicetypes: "Italian, Mexican, Indian, Chinese, French, Thai, Turkish",
    postname: "Alexa Johnson",
    postdate: "Posted date: Jan 4, 2022",
    posttime: "Time: 08:00 AM",
    postdescp:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. ",
    ratingstar: <Rate />,
  });
  

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const blogdetaildata = [
    {
      featuredPostpic: featuredPostpic,
      featuredtitle: "The standard Lorem Ipsum passage,used 1500s",
      featuredPostdate: "October 23, 2022 No Comments",
    },
    {
      featuredPostpic: featuredPostpic,
      featuredtitle: "The standard Lorem Ipsum passage,used 1500s",
      featuredPostdate: "October 23, 2022 No Comments",
    },
    {
      featuredPostpic: featuredPostpic,
      featuredtitle: "The standard Lorem Ipsum passage,used 1500s",
      featuredPostdate: "October 23, 2022 No Comments",
    },
    {
      featuredPostpic: featuredPostpic,
      featuredtitle: "The standard Lorem Ipsum passage,used 1500s",
      featuredPostdate: "October 23, 2022 No Comments",
    },
  ];

  return (
    <div className="shop-page blog-inner-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21} lg={20}>
          <Row style={{ width: "100%", justifyContent: "space-between" }}>
            <Col lg={14}>
              <div className="blog-main-img">
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={chiefData.blogpic}
                  className=""
                />
              </div>
            </Col>

            <Col lg={8}>
              <h5 className="text-16">MAYBE YOU LIKE</h5>
              <h4 className="text-40">Our Featured Post</h4>
              {blogdetaildata.map((item, index) => (
                <div className="feature-post-box" key={index}>
                  <div>
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={item.featuredPostpic}
                      className="circle-cards-img"
                    />
                  </div>
                  <div>
                    <h5 className="text-18-super">{item.featuredtitle}</h5>
                    <p className="text-14">{item.featuredPostdate}</p>
                  </div>
                </div>
              ))}
            </Col>
          </Row>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={20}>
                <h2 className="chief-name">{chiefData.chiefname}</h2>
                <p className="chief-descrip">{chiefData.description}</p>
                <div className="book-and-share-btn">
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    className="web-btn"
                  >
                    Add Reviews
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={24}>
                <h3 className="chief-name">Reviews & ratings</h3>
                <span>{chiefData.ratingstar}</span>
              </Col>
            </Row>
          </div>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={14}>
                <div className="post-media">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={chiefData.imageSrc}
                    className="circle-cards-img"
                  />
                  <div>
                    <h3 className="super-26">{chiefData.postname}</h3>
                    <p className="chief-descrip" style={{ margin: "0" }}>
                      {chiefData.postdescp}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={10} className="for-lg-end">
                <div className="for-lg-end">
                  <p className="chief-descrip" style={{ margin: "0" }}>
                    {chiefData.postdate}
                  </p>
                  <p className="chief-descrip" style={{ margin: "0" }}>
                    {chiefData.posttime}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="service-type-box">
            <Row style={{ width: "100%" }}>
              <Col lg={24} md={24} xs={24}>
                <>
                  <h3 className="super-26">Post a Comment</h3>
                  <TextArea
                    rows={8}
                    placeholder="Your message here.."
                    maxLength={6}
                    style={{ margin: "15px 0" }}
                  />
                  <Button
                    style={{
                      cursor: "pointer",
                    }}
                    className="web-btn"
                  >
                    Post Now
                  </Button>
                </>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InnerBlog;
