import React from "react";
// import AuthLayout from "../../components/";
import { Layout, Col, Row, theme, Input, Image, Button } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import maskpic2 from "../../../src/assets/mask-pic2.png";
import maskpic3 from "../../../src/assets/mask-pic3.png";

function AboutUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="shop-page about-us-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={21}>
            <Row style={{ width: "100%", padding: "20px 0" }}>
              <Col lg={24}>
                <h4 className="service-heading">About Us</h4>
              </Col>
            </Row>

            <div className="service-provider-box inner-about">
              <Row style={{ width: "100%", justifyContent: "end" }}>
                <Col xs={23} md={24}>
                  <div className="">
                    <Row justify={"center"} style={{ paddingBottom: "60px" }}>
                      <Col xs={24} md={19}>
                        <Row style={{ alignItems: "center" , justifyContent:"space-between" }}>
                          <Col xs={24} md={9}>
                            <Image
                              preview={false}
                              alt={"Failed to load image"}
                              src={maskpic2}
                              className="forimg-border"
                            />
                          </Col>
                          <Col xs={24} md={13}>
                            <div className="provider-content-box">
                              <h3>HERE TO MAKE YOUR LIFE EASIER</h3>
                              <p>
                              I Got U is a team of home experts and carers who are based in Milwaukee. We are experienced in serving many homes in the city, covering a range of your needs. We offer lawn mowing, cleaning, masonry, electrical work, plumbing, snow removal, pest control, and a helping hand for anything else you need at home.
 <br />
                                <br />
                                We are also good caregivers. So, if you are sick or need to care for senior citizens, kids, or pets, we are here to make your life easier. You can count on us, because we got you!


                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  {/* <div className="">
                    <Row justify={"center"} style={{ paddingBottom: "60px" }}>
                      <Col xs={24} md={19}>
                        <Row style={{ alignItems: "center", justifyContent:"space-between" }}>
                          <Col xs={24} md={13}>
                            <div className="provider-content-box">
                              <h3>The standard Lorem Ipsum passage</h3>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the when an unknown printer took a galley of
                                type and scrambled it to make a type specimen
                                book. It has survived not only five centuries,
                                but also the leap into electronic typesetting,
                                remaining essentially unchanged <br />
                                <br />
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the when an unknown printer.
                              </p>
                            </div>
                          </Col>
                          <Col xs={24} md={9}>
                            <Image
                              preview={false}
                              alt={"Failed to load image"}
                              src={maskpic3}
                              className="forimg-border"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div> */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default AboutUs;
