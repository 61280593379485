import React from "react";
import { Layout, Col, Row, Button, Form, Input, Checkbox } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";

import swal from "sweetalert";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in

  const onFinish = (values) => {
    setLoading(true);

    Post(AUTH.login, {...values,role:'SERVICE_PROVIDER'})
      .then((response) => {
        setLoading(false);

        console.log("response.status",response)



        if (response?.status == 200) {
          dispatch(
            addUser({ user: response.data.user , token: response.data.token })
          );
          swal("Success!","User Successfully Registered", "success");
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background loginas-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col lg={10}>
            <div className="login-card">
              <h4 className="auth-card-title">Login</h4>
              <Form
                layout="vertical"
                name="basic"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input valid email!",
                      // warningOnly: true,
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Email Address"
                    style={{
                      borderRadius: "5px",
                      background: "white",
                      fontSize: "14px",
                      padding: "10px 20px",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      type: "string",
                      min: 8,
                      message: "password must be atleast 8 characters!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Password"
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      padding: "10px 20px",
                    }}
                  />
                </Form.Item>
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Button
                      type="link"
                      className="forget-text"
                      style={{
                        float: "right",
                      }}
                      onClick={() => navigate("/forget-password-1")}
                    >
                      Forgot Password?
                    </Button>
                  </Col>
                </Row>
                <br />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="web-btn"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    {loading ? "Loading..." : "Login"}
                  </Button>
                </Form.Item>
                <div className="already-login">Not a User? <span onClick={() => navigate('/createAccount')}>Signup</span> Now</div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Login;
