import { useState } from "react";
import { Col, Row, Image, Button } from "antd";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import serviceslider1 from "../../../assets/serviceslider1.png";
import cleaning from "../../../assets/cleaning.png";
import lighting from "../../../assets/lighting.png";
import childcare from "../../../assets/childcare.png";
import { useNavigate } from "react-router";

const Servicecategories = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([
    serviceslider1,
    cleaning,
    lighting,
    childcare,
    serviceslider1,
    cleaning,
    lighting,
    childcare,
  ]);
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
  };

  return (
    <div className="how-work-section">
      <Row style={{ width: "100%", justifyContent: "end" }}>
        <Col xs={23} md={21}>
          <div className="servicebox">
            <Row style={{ paddingBottom: "60px" }} className="for-justify">
              <Col xs={24} md={6} lg={6}>
                <h4 className="service-h4">
                  explore any services for booking & reservation
                </h4>
                <h2 className="service-h2">Explore Service Categories</h2>
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  className="web-btn"
                  onClick={() => navigate("/categories")}
                >
                  View All Categories
                </Button>
              </Col>
              <Col xs={24} md={12} lg={13}>
                <p className="service-p">
                I Got U is a one-stop destination for a wide range of home services. From lawn work to electrical repairs, moving assistance to dry cleaning, and everything in between, we cover it all for you. Our team is committed to providing you with a helping hand for any need you may name. So, no matter what your needs are, we’ve got you!
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24}>
                <AliceCarousel
                  mouseTracking
                  disableButtonsControls
                  autoPlay
                  infinite
                  responsive={responsive}
                >
                  {services.map((item, index) => {
                    return (
                      <div className="for-slider-x">
                        <Image
                          src={item}
                          preview={false}
                          className="slider-img"
                        />
                      </div>
                    );
                  })}
                </AliceCarousel>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Servicecategories;
