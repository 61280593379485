import React from "react";
// import AuthLayout from "../../components/";
import { Layout, Input } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import ServicePage from "../../views/serviceProvidersPrivateEvent/servicePage";

// import router from "next/router";

function ServiceProvidersPrivateEvent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  console.log("state",location)

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
    <ServicePage state={location.state}/>

    </Layout>
  );
}

export default ServiceProvidersPrivateEvent;



