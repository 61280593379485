import { Layout, Modal, message } from 'antd';
import ClientHeader from './ClientHeader';
import ClientFooter from './ClientFooter';




const ClientLayout = ({ children, head, footer= true }) => {
    return (
        <Layout style={{ backgroundColor: "transparent", scrollBehavior: "smooth", position:'relative' }}>
         
            <ClientHeader />
            {children}
          {footer && <ClientFooter />}
        </Layout>
    );
}
export default ClientLayout