import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  Button,
  Popover,
  Skeleton,
  Table,
  Select,
  Pagination,
  DatePicker,
} from "antd";

import dayjs from "dayjs";
import { FaSearch, FaFilter, FaCaretDown, FaEye } from "react-icons/fa";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import { Get } from "../../config/api/get";
import { USERS } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function OrderHistory() {
  const token = useSelector((state) => state.user.userToken);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([
    {
      id: 1,
      orderNumber: "2341",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      isActive: true,
    },
    {
      id: 2,
      orderNumber: "2341",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      isActive: false,
    },
    {
      id: 3,
      orderNumber: "2341",
      serviceType: "ABC Service Type",
      dateService: "Sept 14, 2021",
      amountPaid: "$150",
      isActive: false,
    },
    // Add more user objects as needed
  ]);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    from: null,
    to: null,
  });

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;

  useEffect(() => {
    getUsers();
  }, []);

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    getUsers(pageNumber);
  };

  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  const handleStatusChange = (value) => {
    setFilter({
      ...filter,
      status: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
    getUsers(paginationConfig.pageNumber, paginationConfig.limit, "", true);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    getUsers(1, pageSize);
  };

  const handleStatus = async () => {
    try {
      const index = users.findIndex((user) => user.id == selectedUser.id);
      const newUsers = [...users];

      console.log(index);

      newUsers[index].isActive = !selectedUser.isActive;
      setModalOpen(false);
      setUsers(newUsers);
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(
    "users",
    users.map((item) => item.isActive)
  );

  const getUsers = async (pageNumber, pageSize, search, reset = false) => {
    setLoading(true);
    try {
      const response = await Get(USERS.get, token, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
        status: reset ? "" : filter.status || null,
        keyword: search ? search : null,
        from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
        to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.docs) {
        setUsers(response?.docs);
        setPaginationConfig({
          pageNumber: response?.page,
          limit: response?.limit,
          totalDocs: response?.totalDocs,
          totalPages: response?.totalPages,
        });
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  console.log("paginationConfig", paginationConfig);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 10 && "0") + (index + 1),
    },
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      key: "fullname",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "email",
    },
    {
      title: "Date of Service",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (value, item, index) => (
        <Select
          className={value ? "greenSelect" : "redSelect"}
          suffixIcon={<FaCaretDown style={{ fontSize: "16px" }} />}
          value={value ? "active" : "inactive"}
          bordered={false}
          onChange={() => {
            setModalOpen(true);
            setSelectedUser(users[index]);
          }}
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
          ]}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <FaEye
          style={{ fontSize: "16px", color: "#C90000", cursor: "pointer" }}
          onClick={() => navigate("/myProfile/orderHistoryDetail/" + item)}
        />
      ),
    },
  ];

  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p className="mainLabel" style={{ padding: "10px" }}>
          Filter
        </p>
      </div>
      <hr style={{ margin: 0 }} />

      <div className="filterDropdownBody">
        <p className="mainLabel">Creation Date:</p>
        <DatePicker
          className="mainInput filterInput"
          value={filter.from}
          onChange={(e) => handleFrom(e)}
        />
        <DatePicker
          className="mainInput filterInput"
          value={filter.to}
          onChange={(e) => handleTo(e)}
        />

        <p className="mainLabel">Filter by Status:</p>

        <Select
          size={"large"}
          className="filterSelectBox"
          placeholder="Select Status"
          value={filter.status}
          onChange={(e) => handleStatusChange(e)}
          style={{
            width: "100%",
            marginBottom: "10px",
            textAlign: "left",
          }}
          options={[
            { value: "active", label: "Active" },
            { value: "inactive", label: "Inactive" },
          ]}
        />

        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="web-btn"
          onClick={() => getUsers()}
        >
          Apply
        </Button>
        <Button
          type="primary"
          shape="round"
          block
          size={"large"}
          className="web-btn"
          onClick={() => resetFilter()}
        >
          Clear All
        </Button>
      </div>
    </div>
  );

  return (
    <div className="profile-right-box">
      <h3 className="text-40">Orders & History</h3>
      <Row>
        <Col lg={24} md={24}>
          <div className="boxDetails">
            <Row style={{ padding: "10px 20px" }}>
              <Col
                xs={24}
                md={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ maxWidth: "450px" }}
                  className="mainInput dashInput search-input"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  suffix={
                    <div
                      className="search-web-btn"
                      onClick={() =>
                        getUsers(1, paginationConfig.limit, filter.keyword)
                      }
                    >
                      Search
                    </div>
                    // <FaSearch
                    // className="web-btn"
                    //   style={{
                    //     color: "#3c5a92",
                    //     fontSize: 16,
                    //     cursor: "pointer",
                    //   }}
                    //   onClick={() =>
                    //     getUsers(1, paginationConfig.limit, filter.keyword)
                    //   }
                    // />
                  }
                  onPressEnter={(e) =>
                    getUsers(1, paginationConfig.limit, filter.keyword)
                  }
                />
                &emsp;
                <Popover
                  content={filterContent}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                  placement="bottomRight"
                  arrow={false}
                >
                  <Button
                    style={{
                      padding: "10px 15px",
                      height: "auto",
                      // backgroundColor: "#3c5a92",
                    }}
                    className="fltr-btn"
                  >
                    <HiAdjustmentsHorizontal
                      style={{ fontSize: "20px", color: "white" }}
                    />
                  </Button>
                </Popover>
              </Col>
            </Row>

            <Row style={{ padding: 20, overflow: "auto" }}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Skeleton active />
                  <br />
                </div>
              ) : (
                <Table
                  className="styledTable"
                  dataSource={users}
                  columns={columns}
                  pagination={false}
                />
              )}
            </Row>
            <Row style={{ padding: "10px 20px" }}>
              <Col xs={24} md={12}>
                <p>{message}</p>
              </Col>
              <Col
                xs={24}
                md={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Pagination
                  className="styledPagination"
                  onChange={(e) => handlePageChange(e)}
                  current={parseInt(paginationConfig.pageNumber)}
                  pageSize={paginationConfig.limit}
                  total={paginationConfig.totalDocs}
                  itemRender={itemRender}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderHistory;
