import { Col, Row, Image } from "antd";
import banner from "../../../assets/baner.png";

const Topbanner = ({ image1, image2, title, description, titleStyle }) => {
  return (
    <div className="banner-section">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={24}>
          <div className="culturally-persistent">
            <Row>
              <Col xs={24} md={24}>
                <div className="main-banner-box">
                  <h1>One Step Solution</h1>
                  <h3>For Your <span>Needs</span></h3>
                  <p>Order any service, anytime from anywhere</p>
                  {/* <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={banner}
                    className="forimg-border"
                  /> */}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Topbanner;
