// export const BASE_URL = "https://lm.demos2clients.com/api"
// export const UPLOADS_URL = "https://lm.demos2clients.com/uploads"

export const SITE_NAME = 'My Website';
export const countries = ['USA', 'Canada', 'UK'];

const { NODE_ENV } = process.env
const { hostname } = window.location

const servers = {
  local: "http://localhost:5085",
  customDev: "https://react.customdev.solutions:5085",
  live: "https://thirdcharm.net:5085"
}

var URL;
if (NODE_ENV === "production" && hostname === "react.customdev.solutions") URL = servers.customDev
else if (NODE_ENV === "production" && hostname === "thirdcharm.net") URL = servers.live
else URL = servers.local

export const BASE_URL = URL + "/api"
export const UPLOADS_URL = URL


export const AUTH = {
    login: "/Auth/login",
    changepassword:"/Auth/changepassword",
    signup: "/Users/register"
}


export const USERS = {
    EditProfile: "/Users/edit"
}


export const CATEGORIES = {
    getAllCategories: "/Category/"
}

export const SERVICES = {
    getAllServices: "/Services/"
}

export const BOOKING = {
    getAllServiceProviders: "/bookings/search"
}







