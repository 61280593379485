import { useState } from "react";
import { Col, Row, Card, Image, Rate, Button, Skeleton, Layout } from "antd";

import { Link, useNavigate } from "react-router-dom";

import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import cardimg4 from "../../../assets/card-img4.png";
import cardimg5 from "../../../assets/card-img5.png";
import cardimg6 from "../../../assets/card-img6.png";
import { useEffect } from "react";

const ServiceblogDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      image: cardimg1,
      title: "Lawn Cleaning and Maintenance Services on I Got U ",
      description:
        "Trimming lawn grass and bushes is one of the most tedious chores that you have to do weekly. However, an easy way around it is to opt for a reliable lawn maintenance service that keeps your garden clean and beautiful...",
      ratingstar: <Rate />,
    },
    {
      _id: 2,
      image: cardimg2,
      title: "Cleaning",
      description:
        "Our cleaning services ensure your space shines and sparkles. We offer deep cleaning, regular maintenance, and specialized solutions to keep your home or office fresh and tidy.",
      ratingstar: <Rate />,
    },
    {
      _id: 3,
      image: cardimg3,
      title: "Moving/hauling",
      description:
        "Moving to a new place? Let us know and we our professional moving and hauling team will safely transport your belongings to your new home in a seamless and hassle-free.",
      ratingstar: <Rate />,
    },
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="shop-page">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={19}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <h2 className="service-heading" style={{ padding: "24px 0" }}>
                  BLOG Details
                </h2>
                <h4 className="heading-40">
                How to Choose the Right Cleaning Service? 
                </h4>
                <p className="descrip-p">
                In our fast-paced lives, we often do not have the time to do our house chores. As a result, we are unable to maintain a clean and organized home. That's where house cleaning services come in. They allow you to keep your house in order without doing all the chores on your own.  <br/>But whether you go for daily cleaning services or deep cleaning sessions, you must choose the right services according to your needs. Here are some tips on how you can choose the right cleaning services for your home.  
                </p>

                <h5 className="heading-20">Customizable Cleaning Packages </h5>
                <p  className="descrip-p">The cleaning services company must have an option for customizable packages. It will allow you to opt for a package that can cover the specific needs of your home. Also, it makes them more affordable as you only get the services that are needed for your home.  </p>

                <h5 className="heading-20">Pricing and Transparency </h5>
                <p  className="descrip-p">Pricing can vary significantly between cleaning services. Get quotes from multiple companies and compare their pricing structures. Be cautious of extremely low prices, as they might indicate subpar service. Transparent pricing with no hidden fees is essential for a positive client experience.  </p>

                <h5 className="heading-20">Scheduling and Reliability </h5>
                <p  className="descrip-p">The reliability of a cleaning service is crucial. Ensure the company can accommodate your preferred cleaning schedule, whether it's a one-time appointment or a recurring service. Punctuality and consistency in their services are essential for your convenience.  
</p>

                <h5 className="heading-20">Good Communication and Customer Service </h5>
                <p  className="descrip-p">Besides, good communication and customer service are essential features. It allows you to communicate your needs and get the services you want. We at I Got U value your choices and offer transparency with our customer service.  </p>

                <h5 className="heading-20">Equipment and Supplies  </h5>
                <p  className="descrip-p">Inquire about the cleaning equipment and supplies used by the company. Reputable services should have professional-grade equipment and quality cleaning products. This ensures an effective and thorough cleaning process.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ServiceblogDetails;
