import { Col, Row, Image, Button } from "antd";
import "react-alice-carousel/lib/alice-carousel.css";
import maskpic from "../../../assets/mask-pic.png";
import Workicon1 from "../../../assets/work-icn.png";

const Trustedpeople = () => {
  return (
    <div className="trustpeople-box">
      <Row style={{ width: "100%", justifyContent: "end" }}>
        <Col xs={23} md={24}>
          <div className="">
            <Row justify={"center"} style={{paddingBottom:"30px"}}>
              <Col xs={24} md={19}>
                <Row style={{ justifyContent: "center" }}>
                  <Col xs={24} md={17}>
                    <div className="heading-box">
                      <h6>Home Services that Cover All Your Needs</h6>
                      <h4>A NETWORK OF HOUSE SERVICES PROFESSIONALS TRUSTED BY THOUSANDS</h4>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col xs={23} md={22} lg={14}>
                <div className="">
                  <Row>
                    <Col xs={24} md={8} lg={8}>
                      <div className="yellow-box">
                        <div className="inner-white-div">
                            <h6>Active Users</h6>
                            <h4>100k+</h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <div className="pink-box">
                        <div className="inner-white-div">
                            <h6>Oriental Clients</h6>
                            <h4>200k+</h4>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <div className="blue-box">
                        <div className="inner-white-div">
                            <h6>Feedback Given</h6>
                            <h4>3.5M</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Trustedpeople;
