import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../../redux/slice/authSlice";

import { UPLOADS_URL } from "../../config/constants/api";

// import swal from "sweetalert";
import Profile from "../../assets/profile.png";

import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Image,Modal,Typography } from "antd";
import { Navigate, Outlet, useNavigate } from "react-router";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("My Profile", "", <PieChartOutlined />),
  getItem("Orders & History", "order_history", <DesktopOutlined />),
  getItem("Change Password", "updatePassword", <FileOutlined />),
  getItem("Logout", "logout", <FileOutlined />),
];

function MyProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const [collapsed, setCollapsed] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const onClick = (e) => {
    console.log("click ", e);
    if(e.key == "logout"){
      setLogoutModal(true)
    }else{
      navigate(e.key);
    }
  };

  
  const logout = () => {
    setLogoutModal(false);

    dispatch(removeUser());
    navigate("/");
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={270}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={{
          background: "#fff",
          padding: "20px 0px 5px 0",
        }}
        className="profile-sidebar"
      >
        <div className="demo-logo-vertical" />
        <div className="profile-pic-flex">
          <Image
            preview={false}
            width={60}
            height={60}
            style={{objectFit:'cover', borderRadius:'10px'}}
            alt={"Failed to load image"}
            src={!user?.image
              ? Profile
              : UPLOADS_URL + "/" + user?.image}
            className="circle-cards-img"
          />
          <div>
            <h5>{user?.firstname + " " + user?.lastname}</h5>
            <p>{user?.email}</p>
          </div>
        </div>
        <div style={{padding:"0px 20px"}}>
        <Menu
          onClick={onClick}
          theme="wihte"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
        </div>
    
      </Sider>
      <Layout>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Modal
        visible={logoutModal}
        centered
        onOk={() => logout()}
        onCancel={() => setLogoutModal(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #203453",
            color: "#203453",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#203453",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #203453",
          },
        }}
      >
        
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          Logout
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Are You Sure You Want To Logout ?
        </Typography.Text>
      </Modal>
    </Layout>
  );
}

export default MyProfile;
