import React from "react";
// import AuthLayout from "../../components/";
import {
  Layout,
  Col,
  Row,
  Button,
  theme,
  Form,
  Input,
  Select,
  Space,
  message,
  Upload,
} from "antd";
// import { UploadOutlined } from '@ant-design/icons';
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";

import swal from "sweetalert";

function LoginAs() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleChange = () => {
  //   swal("", "Congrats! You have been registered successfully", "success",);
  // };


  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background loginas-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={16}>
            <Row>
              <Col lg={12}>
                <div className="loginas-card">
                  <BiUserCircle className="icon--size-74" />
                  <h4>Login As user</h4>
                  <Button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/login")}
                    className="web-btn"
                  >
                    Go <BsArrowRight className="go-icon" />
                  </Button>
                </div>
              </Col>

              <Col lg={12}>
                <div className="loginas-card">
                  <AiOutlineSetting className="icon--size-74" />
                  <h4>Login As Service Provide</h4>
                  <Button
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => navigate("/loginServiceProvider")}
                    className="web-btn"
                  >
                    Go <BsArrowRight className="go-icon" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default LoginAs;
