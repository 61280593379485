import { useState } from "react";
import { Col, Row, Card, Image, Rate, Button, Skeleton, Layout } from "antd";

import { Link, useNavigate } from "react-router-dom";

import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import cardimg4 from "../../../assets/card-img4.png";
import cardimg5 from "../../../assets/card-img5.png";
import cardimg6 from "../../../assets/card-img6.png";
import { useEffect } from "react";

const ServiceblogDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      image: cardimg1,
      title: "Lawn Cleaning and Maintenance Services on I Got U ",
      description:
        "Trimming lawn grass and bushes is one of the most tedious chores that you have to do weekly. However, an easy way around it is to opt for a reliable lawn maintenance service that keeps your garden clean and beautiful...",
      ratingstar: <Rate />,
    },
    {
      _id: 2,
      image: cardimg2,
      title: "Cleaning",
      description:
        "Our cleaning services ensure your space shines and sparkles. We offer deep cleaning, regular maintenance, and specialized solutions to keep your home or office fresh and tidy.",
      ratingstar: <Rate />,
    },
    {
      _id: 3,
      image: cardimg3,
      title: "Moving/hauling",
      description:
        "Moving to a new place? Let us know and we our professional moving and hauling team will safely transport your belongings to your new home in a seamless and hassle-free.",
      ratingstar: <Rate />,
    },
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="shop-page">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={19}>
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col lg={24}>
                <h2 className="service-heading" style={{ padding: "24px 0" }}>
                  BLOG Details
                </h2>
                <h4 className="heading-40">
                5 Useful Tips on Moving and Packing 
                </h4>
                <p className="descrip-p">
                The moving out process can be overwhelming as it requires a great deal of physical exertion. You get confused about where to begin your packing or how to ensure that all your accessories remain safe during the transport. To help you with that, here we have discussed five useful tips that will help you make a smooth transition to your new home.  
                </p>

                <h5 className="heading-20">Declutter </h5>
                <p  className="descrip-p">Packing your stuff for shifting to a new place is a tedious task. When you begin to pack, you see so many belongings you have kept for years but have hardly used. These things are unnecessary, and you should ruthlessly declutter to ensure that you move into your home with only important items for a fresh start. </p>

                <h5 className="heading-20">Make a Comprehensive Checklist </h5>
                <p  className="descrip-p">The first thing that you need to do to prepare for your relocation is to make a checklist of all your belongings. Firstly, you should include all your essentials like your documents, first aid and medicines, your gadgets and devices, and other essentials. Then, make an extensive list that includes all your stuff, from food supplies to furniture. This will help you label all your boxes correctly, and you won’t miss out on any item. </p>

                <h5 className="heading-20">Choosing the Right Moving Services </h5>
                <p  className="descrip-p">Moving out on your own or with unprofessional movers can result in a disaster. That's why you need to leverage a good moving service that helps you pack your stuff properly and transfer it to your new house securely. These service providers can solve all your problems with moving. They can transport fragile and delicate objects securely and even help you transfer your pets to your new home.  
You can opt for I Got U’s moving services, which are one of the best in this regard. We offer affordable services that come with moving insurance, so you can carefreely transport your stuff to your new home.  
</p>

                <h5 className="heading-20">Benefit from Cleaning Services </h5>
                <p  className="descrip-p">You should also opt for cleaning services before you move out to the new place. It is essential as the place might be dirty or have pests. Getting a deep clean for the home will prepare it for you so you can easily shift.<br/>You may explore our house cleaning services that we offer with moving services that give you a hassle-free relocation process.  </p>

                <h5 className="heading-20">Ensure to Label Boxes Properly </h5>
                <p  className="descrip-p">Another crucial part of moving out is correct labeling. Labeling the box is important to sort your belongings correctly, as it helps you unpack and organize the house. However, you may also sort them by using different colored boxes for the same category of items.  </p>

                <h5 className="heading-20">Prep Ahead for a Smooth Move Out  </h5>
                <p  className="descrip-p">Moving day can be an overwhelming experience, but being well-prepared with the right essentials can make it significantly more manageable. From packing supplies to cleaning materials, personal necessities, and professional movers, having a plan and the necessary items in place will help you achieve a smooth and successful transition to your new home.</p>

              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ServiceblogDetails;
