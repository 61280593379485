import React, { useState } from "react";
import { Layout, Col, Row, Button, Form, Input } from "antd";
import { useNavigate } from "react-router";

import Modals from "../../../src/components/Modals";

import swal from "sweetalert";

function ForgetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("/forgot-password-2");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => {
    setShow1(true);
  };

  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background loginas-box">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col lg={10}>
            <div className="login-card">
              <h4 className="auth-card-title">Reset Password</h4>
              <h4 className="auth-card-sub-text">Enter a new password</h4>
              <Form
                layout="vertical"
                name="basic"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      type: "string",
                      min: 8,
                      message: "password must be atleast 8 characters!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Password"
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      padding: "10px 20px",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirm Password",
                    },
                    {
                      type: "string",
                      min: 8,
                      message: "password must be atleast 8 characters!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confirm your password"
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px",
                      padding: "10px 20px",
                    }}
                  />
                </Form.Item>
                <br />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="web-btn"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={handleShow1}
                  >
                    Continue
                  </Button>
                </Form.Item>
                <div className="already-login">
                  Back to <span onClick={() => navigate("/login")}>Login</span>{" "}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>

      <Modals
        open={show1}
        handleOk={() => setShow1(false)}
        handleCancel={() => setShow1(false)}
        text="Your password has been reset. Please login to continue."
        footer={[
          <Button
            key="submit"
            type="primary"
            className="web-btn"
            onClick={() => navigate("/login")}
          >
            Continue
          </Button>,
        ]}
      />
    </Layout>
  );
}

export default ForgetPassword;
