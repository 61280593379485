import React from "react";
// import AuthLayout from "../../components/";
import {
  Layout,
  Col,
  Row,
  Button,
  theme,
  Form,
  Input,
  Select,
  Space,
  message,
  Upload,
} from "antd";
// import { UploadOutlined } from '@ant-design/icons';
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";

import swal from "sweetalert";

function CreateAccount() {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const { token } = theme.useToken();

  const onFinish = (values) => {
    setLoading(true);

    Post(AUTH.signup, {...values,role:"USER"})
      .then((response) => {
        setLoading(false);

        if (response?.status == 200) {
          dispatch(
            addUser({ user: response.data.data, token: response.data.token })
          );
          swal("Success!","User Successfully Registered", "success");
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <Layout
      className=""
      style={{ backgroundColor: "#fff", minHeight: "100vh" }}
    >
      <div className="blur-background">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={23} md={18} lg={15}>
            <div className="blur-bg-inner-card">
              <div className="blur-bg-inner-card-form">
                <h2 className="heading-h2" style={{ paddingBottom: "30px" }}>
                  Create Account
                </h2>
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Form.Item
                      label="First Name"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Please enter first name!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter your first name" />
                    </Form.Item>
                    <Form.Item
                      label="last name"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Please enter last name!",

                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(51% - 8px)",
                        margin: "0 0px 0 8px",
                      }}
                    >
                      <Input placeholder="Enter your last name" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter email address!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}
                    >
                      <Input placeholder="Enter your email" />
                    </Form.Item>
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number!",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(51% - 8px)",
                        margin: "0 0px 0 8px",
                      }}
                    >
                      <Input placeholder="Input Phone Number" />
                    </Form.Item>
                  </Form.Item>

                  {/* <Form.Item
                    label="Select User Type "
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Please select user role!",
                      },
                    ]}
                    wrap
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      defaultValue="Select"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleChange}
                      options={[
                        {
                          value: "USER",
                          label: "User",
                        },
                        {
                          value: "SERVICE_PROVIDER",
                          label: "Service Provider",
                        }
                      ]}
                    />
                  </Form.Item> */}

                  <Form.Item
                    label="Password"
                    name="password"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password className="paswordInput" size="small" placeholder="Enter your password" />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirmpassword"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please Re-enter your password",
                      },
                    ]}
                  >
                    <Input.Password className="paswordInput" size="small" placeholder="Re-enter your password" />
                  </Form.Item>

                  {/* <Form.Item
                    label="Upload Profile Picture"
                    name="image"
                    className="blur-bg-inner-card-input"
                    rules={[
                      {
                        required: true,
                        message: "Please Re-enter your password",
                      },
                    ]}
                  >
                     <Upload >
                     <AiOutlineCloudUpload  style={{fontSize:"30px"}}/>
                    </Upload>
                  </Form.Item> */}

                  <Form.Item colon={false}>
                    <Button
                      htmlType="submit"
                      style={{
                        cursor: "pointer",
                        width: "100%",
                      }}
                      className="web-btn"
                    >
                      {loading ? "Loading..."  : "Create Account"}
                    </Button>
                  </Form.Item>

                  <div className="already-login">Already have an Account? <span onClick={() => navigate('/loginAs')}>Login</span></div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default CreateAccount;
