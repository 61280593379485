import { useState } from "react";
import { Col, Row, Card, Image, Rate, Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";

import cardimg1 from "../../../assets/card-img1.png";
import cardimg2 from "../../../assets/card-img2.png";
import cardimg3 from "../../../assets/card-img3.png";
import { useEffect } from "react";

const ServiceblogCards = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [blogs, setBlogs] = useState([
    {
      _id: 1,
      image: cardimg1,
      title: "Lawn Cleaning and Maintenance Services",
      description:
        "Trimming lawn grass and bushes is one of the most tedious chores that you have to do weekly. However, an easy way around it is to opt for a reliable lawn maintenance service that keeps your garden clean and beautiful...",
      ratingstar: <Rate />,
      forlink: <Link to={"/serviceblogDetails"}>Read more</Link>,
    },
    {
      _id: 2,
      image: cardimg2,
      title: "5 Useful Tips on Moving and Packing ",
      description:
        "The moving out process can be overwhelming as it requires a great deal of physical exertion. You get confused about where to begin your packing or how to ensure that all your accessories remain safe during the transport...",
      ratingstar: <Rate />,
      forlink: <Link to={"/serviceblogDetails2"}>Read more</Link>,
    },
    {
      _id: 3,
      image: cardimg3,
      title: "Cleaning services",
      description:
        "In our fast-paced lives, we often do not have the time to do our house chores. As a result, we are unable to maintain a clean and organized home. That's where house cleaning services come in...",
      ratingstar: <Rate />,
      forlink: <Link to={"/serviceblogDetails3"}>Read more</Link>,
    },
  ]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={19}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <h2 className="service-heading" style={{ padding: "24px 0" }}>
                BLOG
              </h2>
            </Col>
          </Row>
          <div className="shop-page-main">
            {loading && (
              <Row>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <Row gutter={25}>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                    <Col lg={8}>
                      <Card
                        // hoverable
                        className="service-card"
                        cover={<Skeleton.Image active width={"100%"} />}
                      >
                        <Skeleton active />
                        <br />
                        <Skeleton.Button active size={"lg"} />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {!loading && (
              <Row>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <Row gutter={16}>
                    {blogs.map((item, index) => (
                      <Col lg={8} key={index}>
                        <Card
                          // hoverable
                          className="service-card"
                          cover={
                            <Image
                              preview={false}
                              alt={"Failed to load image"}
                              src={item.image}
                              className="circle-cards-img"
                            />
                          }
                        >
                          <h4>{item.title}</h4>
                          <p>{item.description}</p>
                          <span className="card-view-detail">{item.forlink}</span>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceblogCards;
